import React from "react";
import otterGIF from '../images/noise.gif'

import {LayoutPages, Seo} from "../components/"


const NotFoundPage = () => {
    return (
        <LayoutPages errorPage={true} className="section-error-page">
            <Seo title="404: Not found" />
            <div className="page-error">
                <img src={otterGIF} alt="404: Not found" className="page-error__img"/>
                <div className="container">
                    <div className="page-error-head">
                        <h1 className="page-error__subtitle">404</h1>
                    </div>
                    <div className="page-error-description">
                        You just hit a route that doesn&#39;t exist... the sadness.
                    </div>
                </div>
            </div>
        </LayoutPages>
    );

};




export default NotFoundPage
